import { render, staticRenderFns } from "./categories.vue?vue&type=template&id=d240ada8&scoped=true&"
import script from "./categories.vue?vue&type=script&lang=js&"
export * from "./categories.vue?vue&type=script&lang=js&"
import style0 from "./categories.vue?vue&type=style&index=0&id=d240ada8&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d240ada8",
  null
  
)

component.options.__file = "categories.vue"
export default component.exports